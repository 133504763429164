<template>
  <div>
    <v-dialog v-model="dialog" max-width="720" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" v-if="isEdit">
                <v-text-field
                  v-model="item.MenuId"
                  label="รหัส"
                  dense
                  required
                  readonly
                  ref="MenuId"
                  @keydown.enter="NextFocus('MenuTypeId')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="item.MenuTypeId"
                  :items="menuTypeList"
                  item-value="MenuTypeId"
                  item-text="MenuTypeName"
                  :return-object="false"
                  dense
                  label="ประเภทเมนู"
                  ref="MenuTypeId"
                  @change="NextFocus('MenuName')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="item.MenuName"
                  label="ชื่อ"
                  dense
                  required
                  ref="MenuName"
                  @keydown.enter="NextFocus('MenuBarcode')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="item.MenuBarcode"
                  label="บาร์โค้ด"
                  dense
                  required
                  ref="MenuBarcode"
                  @keydown.enter="NextFocus('UnitId')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="item.UnitId"
                  :items="unitList"
                  item-value="UnitId"
                  item-text="UnitName"
                  :return-object="false"
                  dense
                  label="หน่วย"
                  ref="UnitId"
                  @keydown.enter="NextFocus('Price')"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.number="item.Price"
                  label="ราคา"
                  type="number"
                  dense
                  required
                  ref="Price"
                  @keydown.enter="NextFocus('OnlineStatus')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="item.OnlineStatus"
                  :items="enumStatus"
                  item-value="id"
                  item-text="name"
                  :return-object="false"
                  dense
                  label="สั่งออนไลน์"
                  ref="OnlineStatus"
                  @keydown.enter="NextFocus('OnlineSort')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.number="item.OnlineSort"
                  label="ลำดับออนไลน์"
                  type="number"
                  dense
                  required
                  ref="OnlineSort"
                  @keydown.enter="NextFocus('Status')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="isEdit" sm="6">
                <v-autocomplete
                  v-model="item.Status"
                  :items="enumStatus"
                  item-value="id"
                  item-text="name"
                  :return-object="false"
                  dense
                  label="สถานะ"
                  ref="Status"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" class="mt-n5">
                <v-checkbox
                  v-model="item.VatType"
                  :false-value="0"
                  :true-value="1"
                  label="ภาษี"
                  dense
                ></v-checkbox>
              </v-col>
              <!-- <v-col cols="12" sm="6">
                <input
                  type="file"
                  accept="image/*"
                  @change="previewImage"
                  class="form-control-file"
                  id="my-file"
                />
              </v-col> -->
              <v-col cols="12" sm="6">
                <template v-if="preview">
                  <img
                    :src="preview"
                    class="img-fluid"
                    style="width: 180px; height: 180px"
                  />
                  <p class="mb-0">file name: {{ image.name }}</p>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel"> ยกเลิก </v-btn>
          <v-btn class="primary" @click="submit"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEnum from "@js/enum";
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
  props: ["modal", "item", "itemIndex"],
  data() {
    return {
      preview: null,
      image: null,
      enumStatus: baseEnum.status.data,
      dialog: false,
      itemEdit: {},
      indexEdit: -1,
      unitList: [],
      menuTypeList: [],
    };
  },
  mounted() {
    this.getUnitList();
    this.getMenuTypeList();
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("settings", ["parameter"]),
    dialogTitle() {
      return this.indexEdit == -1 ? "เพิ่ม" : "แก้ไข";
    },
    isEdit() {
      return this.indexEdit == -1 ? false : true;
    },
  },
  methods: {
    ...mapActions("settings", [
      "actFoodMenuCreate",
      "actFoodMenuUpdate",
      "actUnitGets",
      "actMenuTypeGets",
    ]),
    closeModal() {
      this.$emit("close", false);
    },
    cancel() {
      this.closeModal();
    },
    getMenuTypeList() {
      this.actMenuTypeGets().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.menuTypeList = data.result;
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    getUnitList() {
      this.actUnitGets().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.unitList = data.result;
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    submit() {
      if (this.image != null) {
        this.itemEdit.Photo = this.image.name;
        console.log(this.itemEdit.Photo);
      }

      if (this.indexEdit == -1) {
        //เพิ่ม
        this.itemEdit.CreatedBy = this.user.UserId;
        this.itemEdit.ModifiedBy = this.user.UserId;
        this.actFoodMenuCreate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      } else {
        //แก้ไข
        this.itemEdit.ModifiedBy = this.user.UserId;
        console.log(this.itemEdit);
        this.actFoodMenuUpdate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            // this.uploadImage();
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      }
    },
    previewImage: function (event) {
      var input = event.target;

      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    uploadImage() {
      // const URL = "http://buffet-image.kugthailand.com/Buffet01";
      const URL = this.parameter.PathImage;

      let data = new FormData();
      data.append("name", this.itemEdit.MenuId);
      data.append("file", this.image);

      const headers = { "Content-Type": "image/png" };
      axios.post(URL, data, { headers }).then((res) => {
        res.data.files; // binary representation of the file
        res.status; // HTTP status
      });
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      this.itemEdit = this.item;
      this.image = { name: this.itemEdit.Photo };
      this.preview = this.parameter.PathImage + this.itemEdit.Photo;
    },
    itemIndex() {
      this.indexEdit = this.itemIndex;
    },
  },
};
</script>

<style>
.base-image-input {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}
</style>
